import React from "react"
import classnames from "classnames"

export default function Select({
  isError,
  label,
  onChange,
  onBlur,
  value,
  children,
  id,
  name,
}) {
  const selectProps = { onChange, onBlur, value }

  return (
    <div className="w-full">
      {label && (
        <label
          className="block tracking-wide text-gray-700 mb-2"
          for="grid-state"
        >
          {label}
        </label>
      )}
      <div className="relative">
        <select
          {...selectProps}
          id={id}
          name={name}
          className={classnames(
            "block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-lg focus:outline-none focus:bg-white focus:border-gray-500",
            {
              "border-red-600": isError,
            }
          )}
        >
          {children}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>
  )
}
