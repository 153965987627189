import React, { useState } from "react"
import classNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { sanityConfig } from "../utils/sanity"

import Banner from "../components/banner"
import Formation from "../components/formation"
import Layout from "../components/layout"
import PortableText from "../components/portable-text"
import Seo from "../components/seo"

export default function FundingTemplate({ data, pageContext }) {
  const { slug } = pageContext
  const { page } = data
  const [arrowActive, setArrowActive] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  async function handleSubmit({ form, payload }) {
    if (showSuccess || showError) {
      setShowSuccess(false)
      setShowError(false)
    }

    // The payload is what will get passed into the message string.
    // We create title etc through const
    const emailData = {
      to: form.recipientEmail,
      title: `A submission was made through the ${form.name} form`,
      data: payload,
      form,
    }

    try {
      const res = await fetch("/.netlify/functions/send-form-email", {
        method: "post",
        body: JSON.stringify(emailData),
      })

      if (res.ok) {
        setShowSuccess(true)
      } else {
        console.error("Problem", res)
        setShowError(true)
      }
    } catch (err) {
      console.error("Problem", err)
      setShowError(true)
    }
  }

  // Get quicklinks
  function getQuickLinks() {
    const quickLinks = page._rawContent
      ? page._rawContent.filter((item) => item._type === "quickLink")
      : []
    return quickLinks
  }

  // Get feature image object for gatsby-image
  const featureImage = page.featureImage
    ? getGatsbyImageData(
        page.featureImage.asset.id,
        { maxWidth: 1024 },
        sanityConfig
      )
    : null

  return (
    <Layout>
      <Seo title={page.title} />
      <Banner label="Research & Grants">{page.title}</Banner>

      <main className="container mx-auto">
        <div className="px-3 py-3 lg:py-12 lg:flex items-start justify-between">
          <button
            id="subNav"
            onClick={() => setArrowActive(!arrowActive)}
            className="lg:hidden py-1 text-lg font-semibold text-indigo-800 hover:text-indigo-600 flex items-center"
          >
            <span className="pr-2">{page.title}</span>
            <svg
              id="arrowDown"
              className={classNames("w-4 mt-1 fill-current block", {
                hidden: arrowActive,
              })}
              version="1.1"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m2.5 31.301c0-2.6016 1-5.1016 2.8984-7.1016 3.8984-3.8984 10.301-3.8984 14.199 0l30.402 30.301 30.398-30.398c3.8984-3.8984 10.301-3.8984 14.199 0 3.8984 3.8984 3.8984 10.301 0 14.199l-37.496 37.5c-1.8984 1.8984-4.3984 2.8984-7.1016 2.8984-2.6992 0-5.1992-1.1016-7.1016-2.8984l-37.5-37.402c-1.8984-2-2.8984-4.5977-2.8984-7.0977z" />
            </svg>
            <svg
              id="arrowUp"
              className={classNames("w-4 mt-1 fill-current block", {
                hidden: !arrowActive,
              })}
              version="1.1"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m97.5 68.699c0 2.6016-1 5.1016-2.8984 7.1016-3.8984 3.8984-10.301 3.8984-14.199 0l-30.402-30.301-30.398 30.301c-3.8984 3.8984-10.301 3.8984-14.199 0-3.8984-3.8984-3.8984-10.301 0-14.199l37.5-37.5c1.8984-1.8984 4.3984-2.8984 7.1016-2.8984 2.6992 0 5.1992 1.1016 7.1016 2.8984l37.5 37.5c1.8945 2 2.8945 4.5977 2.8945 7.0977z" />
            </svg>
          </button>
          {arrowActive && (
            <ul className="leading-loose font-semibold md:hidden">
              {getQuickLinks().map(
                (link) =>
                  link.title && (
                    <li key={link._key}>
                      <Link
                        className="block p-1 text-indigo-800 hover:text-black"
                        to={`/${slug}#${link.ref}`}
                      >
                        {link.title}
                      </Link>
                    </li>
                  )
              )}
            </ul>
          )}

          {getQuickLinks().length > 8 && (
            <aside
              id="sectionMenu"
              className="hidden lg:block lg:sticky self-start top-0 mt-4"
            >
              <div className="lg:my-3">
                <p className="hidden lg:block lg:text-xl font-semibold text-gray-800 border-b-4 border-gray-200 mb-1">
                  {page.title}
                </p>

                <ul className="leading-loose font-semibold">
                  {getQuickLinks().map(
                    (link) =>
                      link.title && (
                        <li key={link._key}>
                          <Link
                            className="block p-1 text-indigo-800 hover:text-black"
                            to={`/funding-activity/${slug}#${link.ref}`}
                          >
                            {link.title}
                          </Link>
                        </li>
                      )
                  )}
                </ul>
              </div>
            </aside>
          )}

          <article className="leading-loose w-2/3">
            {featureImage && (
              <GatsbyImage className="mb-6 rounded" image={featureImage} />
            )}

            <div className="leading-loose max-w-3xl xl:text-lg mb-6">
              <PortableText blocks={page._rawContent} />
            </div>

            {showError && (
              <p className="mb-6 bg-red-100 border-red-400 border-2 rounded py-2 px-4 text-red-800 font-bold">
                There was a problem submitting the application. Please try again
                or reach out to support if the problem persists.
              </p>
            )}

            {showSuccess && (
              <p className="mb-6 bg-green-100 border-green-400 border-2 rounded py-2 px-4 text-green-800 font-bold">
                Thank you! <span role="img">🎉</span>
                <br />
                Your application was submitted succesfully! We will contact you
                within due course.
              </p>
            )}

            {page.applicationForm && (
              <Formation
                form={page.applicationForm}
                onSubmit={(payload) =>
                  handleSubmit({ form: page.applicationForm, payload })
                }
              />
            )}

            {showSuccess && (
              <p className="mt-6 bg-green-100 border-green-400 border-2 rounded py-2 px-4 text-green-800 font-bold">
                Thank you! <span role="img">🎉</span>
                <br />
                Your application was submitted succesfully! We will contact you
                within due course.
              </p>
            )}

            {showError && (
              <p className="mt-6 bg-red-100 border-red-400 border-2 rounded py-2 px-4 text-red-800 font-bold">
                There was a problem submitting the application. Please try again
                or reach out to support if the problem persists.
              </p>
            )}
          </article>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    page: sanityFunding(id: { eq: $id }) {
      title
      template
      featureImage {
        asset {
          id
        }
      }
      _rawContent
      tags
      applicationDeadline(formatString: "MMMM do, YYYY")
      applicationForm {
        name
        formId {
          current
        }
        recipientEmail
        formFields {
          _key
          formFieldId
          input
          label
          placeholder
          required
          values
          validationRule {
            warning
          }
          initialValue
        }
      }
    }
  }
`
