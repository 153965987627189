// export function createYupSchema(schema, config) {
//   const { id, validationType, validations = [] } = config;
//   if (!yup[validationType]) {
//     return schema;
//   }
//   let validator = yup[validationType]();
//   validations.forEach(validation => {
//     const { params, type } = validation;
//     if (!validator[type]) {
//       return;
//     }
//     console.log(type, params);
//     validator = validator[type](...params);
//   });
//   schema[id] = validator;
//   return schema;
// }

import * as yup from "yup"

// Convert the input type to relevant formik type
function getFormikType(field) {
  const { input } = field

  // This will become a switch
  switch (input) {
    case "date":
      return "date"
    case "number":
      return "number"
    default:
      return "string"
  }
}

// Convert the input type to relevant formik type
function getFieldType(field) {
  const { input } = field

  switch (input) {
    case "url":
      return "url"
    case "email":
      return "email"
  }
}

export function createYupSchema(fields) {
  let schema = {}

  // Map through fields and get the validation types
  fields.forEach(field => {
    const validationType = getFormikType(field)
    const type = getFieldType(field)

    let validator = yup[validationType]()

    if (field.required) {
      if (field.validationRule && field.validationRule.warning) {
        validator = type
          ? validator[type]().required(field.validationRule.warning)
          : validator.required(field.validationRule.warning)
      } else {
        validator = type ? validator[type]().required() : validator.required()
      }
    }

    schema[field.formFieldId] = validator
  })

  if (Object.keys(schema).length > 0) {
    return yup.object().shape({
      ...schema,
    })
  }

  return {}
}
