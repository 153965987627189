import React from "react"
import PropTypes from "prop-types"

// Form component (Formik)
import Form from "./Form"

// Form config (@TODO: Figure out how to extend this for customisation `...` perhaps)
import config from "./form.config"

// @NOTE: Perhaps the user config can be passed in as a prop
function Formation({ form, onSubmit, isLoading }) {
  return (
    <Form
      id={form.formId.current}
      fields={form.formFields}
      config={config}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  )
}

Formation.propTypes = {
  isLoading: PropTypes.bool,
  form: PropTypes.shape({
    formId: PropTypes.shape({
      current: PropTypes.string,
    }),
    formFields: PropTypes.arrayOf(
      PropTypes.shape({
        _key: PropTypes.string,
        formFieldId: PropTypes.string,
        input: PropTypes.string,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        values: PropTypes.arrayOf(PropTypes.string),
        initialValue: PropTypes.string,
        validationRule: PropTypes.shape({
          warning: PropTypes.string,
        }),
      })
    ),
  }),
}

Formation.defaultProps = {
  isLoading: false,
}

export default Formation
