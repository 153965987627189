/**
 * Form component that acts as a wrapper around formik
 */

import React from "react"
import classnames from "classnames"
import { Field, Formik } from "formik"

import { Button } from "../button"
import { createYupSchema } from "./utils"
import Select from "../inputs/select"

// Get initial values
function getInitialValues(fields) {
  const fieldsWithValues = fields.filter((field) => field.initialValue)

  const values = fieldsWithValues
    .map((field) => {
      if (field.formFieldId) {
        return {
          [field.formFieldId]: field.initialValue,
        }
      }

      // If no form field id is present then return `undefined`
      return null
    })
    .filter((f) => f !== null)

  return fieldsWithValues.length ? values : {}
}

function renderInput(input, field, error) {
  // Handle <select> render
  if (input.input === "select") {
    if (input.values) {
      return (
        <Select {...field} isError={error}>
          {input.values.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </Select>
      )
    }

    throw new Error(`
      No options were provided to the <select> element.
      Check the Input Values section in Sanity Studio.
    `)
  }

  // Handle <textarea>
  if (input.input === "textarea") {
    return (
      <textarea
        {...field}
        cols="60"
        rows="10"
        placeholder={input.placeholder}
        className={classnames(
          "w-full px-4 py-2 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none",
          { "border-red-600": error }
        )}
      />
    )
  }

  return (
    <input
      type={input.input}
      placeholder={input.placeholder}
      {...field}
      className={classnames(
        "w-full px-4 py-2 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none",
        {
          "border-red-600": error,
        }
      )}
    />
  )
}

function renderFields(inputs, errors) {
  return inputs.map((input) => {
    return input.formFieldId ? (
      <div key={input._key} className="mb-6">
        <label>{input.label}</label>

        <div>
          {errors[input.formFieldId] && (
            <p className="text-red-600 font-bold mb-0">
              {errors[input.formFieldId]}
            </p>
          )}
          <Field name={input.formFieldId}>
            {({
              field, // { name, value, onChange, onBlur }
              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
              meta,
            }) => {
              return renderInput(input, field, errors[input.formFieldId])
            }}
          </Field>
        </div>
      </div>
    ) : null
  })
}

function Form({ id, fields, onSubmit, isLoading }) {
  const hasValues = Object.keys(getInitialValues(fields)).length
  const initialValues = hasValues
    ? Object.assign({}, ...getInitialValues(fields))
    : {}

  const schema = createYupSchema(fields)
  const formikProps = {
    initialValues: {
      ...initialValues,
      honey6tY4bPYk: "",
    },
    onSubmit: (values) => onSubmit(values),
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
  }

  return (
    <Formik {...formikProps}>
      {({ handleSubmit, handleChange, errors }) => (
        <form onSubmit={handleSubmit} id={id}>
          {Object.keys(errors).length > 0 && (
            <p className="mb-6 bg-red-100 border-red-400 border-2 rounded py-2 px-4 text-red-800 font-bold">
              There was a problem with the information that you have provided.
              Please check all fields and address any errors in the form.
            </p>
          )}
          {renderFields(fields, errors)}
          <input
            type="hidden"
            name="honey6tY4bPYk"
            id="honey6tY4bPYk"
            autoComplete="off"
          />
          <Button type="submit" isLoading={isLoading}>
            Submit
          </Button>

          {Object.keys(errors).length > 0 && (
            <p className="mt-6 bg-red-100 border-red-400 border-2 rounded py-2 px-4 text-red-800 font-bold">
              There was a problem with the information that you have provided.
              Please check all fields and address any errors in the form.
            </p>
          )}
        </form>
      )}
    </Formik>
  )
}

export default Form
